import React, {useRef,useEffect} from 'react'
import styled from 'styled-components'
import Illustration from '../../svg/lightning.svg'
// import LazyLinePainter from 'lazy-line-painter'
import gsap from 'gsap'

const WatermarkWrapper = styled.aside`
  position:absolute;
  top:0;
  left:0;
  pointer-events:none;
  opacity:0;
  /* z-index:-1; */
  svg{

    *{
      fill:none;
      stroke:${props => props.theme.colors.lightest};
      stroke-location:inside;
      stroke-width:2px;
      /* vector-effect:non-scaling-stroke; */
    }
    opacity:.25;
    width:25%;
    height:calc(100% - 6em);
    position:fixed;
    object-fit:contain;
    object-position:bottom center;
    bottom:3em;
    right:6em;
    /* transform:translate3d(50%,0%,0); */
  }
`

const Watermark = (props) => {
  const watermarkWrapperRef = useRef(),
  animation = useRef()
  useEffect(()=>{
    const LazyLinePainter = require("lazy-line-painter");

      // initialise & configure LazyLinePainter
      animation.current = new LazyLinePainter(watermarkWrapperRef.current, {
        "ease":"easeInOutQuad",
        "reverse":true
      });

      animation.current.paint()
      gsap.fromTo(watermarkWrapperRef.current,{
        autoAlpha:0
      },{
        autoAlpha:1,
        duration:.25,
        ease: "quad.out",
      })

    // // paint! :)
    // myAnimation.paint()
  },[])

  return(
    <WatermarkWrapper ref={watermarkWrapperRef } className="watermark fullHeight fullWidth">
      <Illustration />
    </WatermarkWrapper>
  )
}

export default Watermark