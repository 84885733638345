import React, {useContext} from 'react'
import styled from 'styled-components'

import get from 'lodash/get'
import { linkResolver } from '../../utils/linkResolver'
import CaseItem from './caseItem'

const CasesWrapper = styled.div`
  @media (min-width: 1024px) {
    background:${props => props.theme.colors.darkest};
    position:absolute;
    width:${(586/1440)*100}%;
    min-height:100%;
    top:0;
    right:0%;
  }
`
const Cases = (props) => {
  const {
    cases
  } = props

  return(
    <CasesWrapper>
      {cases.map((work, i) => {
        const titleLineOne = get(work,'case.title_line_1.[0].text'),
        titleLineTwo = get(work,'case.title_line_2.[0].text'),
        thumbnail = get(work,'case.thumbnail_file'),
        meta = get(work,'case._meta'),
        url = meta && linkResolver(meta)

        return <CaseItem
          titleLineOne={titleLineOne}
          titleLineTwo={titleLineTwo}
          thumbnail={thumbnail}
          url={url}
        />
      })}
    </CasesWrapper>
  )
}

export default Cases