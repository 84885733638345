import React, {useContext, useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import LazyImage from '../LazyImage'
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from 'gsap'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'

const CaseWrapper = styled.div`
  width:100%;
  overflow:hidden;
  position:relative;
  @media (min-width: 1024px) {
    height:33.33vh;
  }
  padding-bottom:56%;
  header{
    position:absolute;
    width:100%;
    bottom:0;
    left:0%;
    padding:1.5em;
    z-index:1;
    line-height:1.125;
    text-transform:uppercase;
    font-style:italic;
  }
`

const CaseItem = (props) => {
  const {
    titleLineOne,
    titleLineTwo,
    thumbnail,
    url
  } = props,

  dispatch = useContext(GlobalDispatchContext),
  state = useContext(GlobalStateContext),

  imageWrapperRef = useRef(),
  tl = useRef(),
  [active, setActive] = useState(false)

  useEffect(()=>{
    const img= imageWrapperRef.current.querySelectorAll('.img')

    tl.current = gsap.timeline({
      paused: true,
    })
    .to(img,{
      scale:1.05,
      duration:1.5,
      ease: "power2.out",
      autoAlpha:.66
    })
  },[])

  useEffect(()=>{

    active === true && !tl.current.isActive() ? tl.current.play() : tl.current.reverse()
  },[active])


  return(
    <CaseWrapper>
      <TransitionLink
        to={url}
        entry={{
          trigger: ({ node, e, exit, entry }) => {
            window.scrollTo(0, 0);
            dispatch({
              type: 'SET_NAV_ACTIVE',
              navActive: !state.navActive
            })
          }
        }}
      >
        <header>
        <div>
          {titleLineOne}
        </div>
        <div>
          {titleLineTwo}
        </div>
        </header>
        <div
          ref={imageWrapperRef}
          onMouseEnter={()=>setActive(true)}
          onMouseLeave={()=>setActive(false)}
        >
          <LazyImage {...thumbnail} />
        </div>
      </TransitionLink>
    </CaseWrapper>
  )
}

export default CaseItem