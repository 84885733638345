import React, {useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import {cover} from 'polished'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap'

const LazyImageWrapper = styled.div`
  ${cover()}
  overflow:hidden;
  img{
    ${cover()}
    object-fit:cover;
    object-position:center;
    height:100%;
  }
`

const LazyImage = (props) => {
  const {
    alt,
    dimensions,
    url,
    loaded
  } = props,
  lazyImageWrapperRef = useRef(),
  [load, setLoad] = useState(loaded)

  useEffect(()=>{
    ScrollTrigger.create({
      id: "lazyTrigger",
      trigger: lazyImageWrapperRef.current,
      start: "-500% bottom",
      onEnter:()=>{
        setLoad(true)
        // ScrollTrigger.getById("#lazyTrigger").kill()
      }
    });

    return () => {
      ScrollTrigger.getById("lazyTrigger") && ScrollTrigger.getById("lazyTrigger").kill()
    }
  },[])

  return (
    <LazyImageWrapper ref={lazyImageWrapperRef} className="img">
      <img src={load ? url + '&w=1680' : ''} alt={alt} />
    </LazyImageWrapper>
  )
}

export default LazyImage