import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import LogoSVG from "../../svg/logo.svg"
import Navicon from "./navicon"
import TransitionLink from "gatsby-plugin-transition-link"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import HoverEffect from "../HoverEffect"
import gsap from "gsap"

const HeaderWrapper = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1em 1.5em;
  opacity: 0;
  @media (min-width: 562px) {
    padding: 1.5em 3em;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  .logo {
    height: 1.125em;
    path {
      fill: ${props => props.theme.colors.lightest};
    }
  }
`

const Header = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const headerRef = useRef()

  useEffect(() => {
    gsap.to(headerRef.current, {
      opacity: 1,
      duration: 1,
      ease: "power2.out",
    })
  })

  return (
    <HeaderWrapper ref={headerRef}>
      <a href="/">
        <HoverEffect>
          <LogoSVG className="logo" />
        </HoverEffect>
      </a>
      <HoverEffect>
        <Navicon />
      </HoverEffect>
    </HeaderWrapper>
  )
}

export default Header
