import React, {useContext, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '../../context/GlobalContextProvider'
import LogoSVG from '../../svg/lightning.svg'
import gsap from 'gsap'

const LoaderWrapper = styled.aside`
  background:${props => props.theme.colors.darkest};
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:3;
  .logo-wrapper{
    position:fixed;
    top:50%;
    left:50%;
    transform:translate3d(-50%,-50%,0);
    width:calc(6em * (410/861));
    height:6em;
  }
  .logo{
    height:auto;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    overflow:hidden;
    &.back{
      opacity:.25;
    }
    &.front{
      /* opacity:.25; */
      height:20px;
    }
    *{
      fill:none;
      stroke:${props => props.theme.colors.lightest};
      stroke-location:inside;
      stroke-width:1px;
      vector-effect:non-scaling-stroke;
    }
  }
`

const Loader = (props) => {
  const dispatch = useContext(GlobalDispatchContext),
  state = useContext(GlobalStateContext),
  logoTl = useRef(),
  logoRef = useRef(),
  bgTl = useRef(),
  bgRef = useRef(),
  logoWrapperRef = useRef()

  useEffect(()=>{
    bgTl.current = gsap.timeline({
      paused: true,
      onComplete:()=>{
        dispatch({
          type: 'SET_LOADED',
          loaded: true
        })
      },
      onStart:()=>{
        window.scrollTo(0,0)
      }
    })
    .to(bgRef.current,1,{
      autoAlpha:0,
      ease:'power2.out'
    })

    logoTl.current = gsap.timeline({
      paused: false,
      onComplete:()=>{
        bgTl.current.play()
      }
    }).fromTo(logoRef.current,1,{
      height:'0%'
    },{
      height:'100%',
      ease:'power2.out'
    })
    .to(logoWrapperRef.current,.5,{
      yPercent:-5,
      autoAlpha:0,
      ease:'power2.out'
    })

  },[])

  return(
    <>
      {state && !state.loaded &&
        <LoaderWrapper
          ref={bgRef}
        >
        <div className="logo-wrapper" ref={logoWrapperRef}>
          <LogoSVG
            className="logo back"
          />
          <div className="logo front" ref={logoRef}>
          <LogoSVG />
          </div>
        </div>
        </LoaderWrapper>
      }
    </>
  )
}

export default Loader