import React, {useContext, useEffect, useRef} from 'react'
import styled from 'styled-components'
import { useMouseMove } from 'react-use-mouse-move';
import gsap from 'gsap'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'


const CursorWrapper = styled.div`
  position:fixed;
  width:2em;
  height:2em;
  top:-1em;
  left:-1em;
  z-index:3;
  white-space:none;
  pointer-events:none;
  white-space:nowrap;
  .text{
    position:absolute;
    text-transform:uppercase;
    font-style:italic;
    top:50%;
    left:50%;
    transform:translate3d(-50%,-50%,0);
  }
`

const Cursor = () => {
  const mousePos = useMouseMove(1),
  state = useContext(GlobalStateContext),
  cursorWrapperRef = useRef(),
  tl = useRef

  function is_touch_device() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(()=>{
    gsap.to(cursorWrapperRef.current,.5,{
      x:mousePos.x,
      y:mousePos.y
    })
  },[mousePos])

  useEffect(()=>{
    tl.current = gsap.timeline({
      paused:true
    })
    .fromTo(cursorWrapperRef.current,{
      autoAlpha:0
    },{
      autoAlpha:1,
      duration:1,
      ease:'power2.out'
    })
  },[])

  useEffect(()=>{
    state.cursor.visible ? tl.current.play() : tl.current.reverse()
  },[state])

  if(is_touch_device === true){
    return false
  }

  return (
    <CursorWrapper ref={cursorWrapperRef}>
      <div className="text">
        {state && state.cursor.text}
      </div>
    </CursorWrapper>
  )
}

export default Cursor