import React, {useRef, useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'

const HoverEffectWrapper = styled.div`
  position:relative;
  transition:opacity .5s ease-out;
  &:hover{
    opacity:.66;
  }
`
const HoverEffect = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const {
    children
  } = props,
  hoverEffectWrapperRef = useRef(),
  tl = useRef(),
  [active, setActive] = useState()

  // useEffect(()=>{
  //   tl.current = gsap.timeline({
  //     paused: true
  //   })
  //   .to(hoverEffectWrapperRef.current,{
  //     y:-8,
  //     autoAlpha:.5,
  //     immediateRender: false,
  //     ease:"power2.out",
  //     duration:.5
  //   },'intro')
  //   .fromTo(hoverEffectWrapperRef.current,{
  //     autoAlpha:.5
  //   },{
  //     autoAlpha:1,
  //     duration:.5,
  //     immediateRender: false,
  //     ease:"power2.out"
  //   },'outro')
  // })

  // useEffect(()=>{
  //   active === true && !tl.current.isActive() && tl.current.progress(0).play()
  // },[active])

  return(
    <HoverEffectWrapper
      onMouseEnter={()=>{
        setActive(true)
        dispatch({
          type: 'SET_CURSOR',
          cursor:{
            visible:false
          }
        })
      }}
      onMouseLeave={()=>setActive(false)}
    >
      <div ref={hoverEffectWrapperRef}>
        {children}
      </div>
    </HoverEffectWrapper>
  )
}

export default HoverEffect