import React, {useContext, useState, useEffect} from 'react'
import { Transition, SwitchTransition} from 'react-transition-group';
import styled from 'styled-components'
import {
  useStaticQuery,
  graphql
} from "gatsby"

import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'
import TransitionLink from 'gatsby-plugin-transition-link'

import Cookies from 'universal-cookie';

import gsap from 'gsap'
import get from 'lodash/get'
import CTA from '../CTA'

const CookieNoticeWrapper = styled.nav`
  background:${props => props.theme.colors.lightest};
  color:${props => props.theme.colors.darkest};
  position:fixed;
  width:100%;
  right:0;
  bottom:0;
  @media (min-width: 768px) {
    right:3em;
    max-width:412px;
    bottom:3em;
    border-radius:3px;
  }
  @media (min-width: 1024px) {
    width:${(412/1440)*100}%;
  }
  padding:1.5em;
  box-shadow:0 0 2em rgba(0,0,0,.125);
  visibility:hidden;
  p{
    font-family:sans-serif;
    font-weight:500;
    line-height:1.5;
  }
  h3{
    text-transform:uppercase;
    font-style:italic;
  }
  >*{
    &:not(:last-child){
      margin-bottom:1em;
    }
  }
  .close-icon{
    width:16px;
    position:absolute;
    top:1.85em;
    right:1.5em;
    cursor:pointer;
    .line{
      width:100%;
      position:absolute;
      height:2px;
      background:${props => props.theme.colors.darkest};
      &:first-child{
        transform:rotate(45deg);
      }
      &:last-child{
        transform:rotate(-45deg);
      }
    }
  }
`

const CookieNotice = (props) => {
  const cookies = new Cookies();
  const gdprCookieName = 'gatsby-plugin-google-analytics-gdpr_cookies-enabled'

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const [visible, setVisible] = useState(false)

  useEffect(()=>{
    const gdprCookie = cookies.get(gdprCookieName)
    if(gdprCookie === 'true' || gdprCookie === true){
      setVisible(false)
    }else{
      setVisible(true)
    }
  },[cookies])

  const setConsent = (consent) => {
    let date = new Date()
    cookies.set(gdprCookieName, consent ? true : false, { path: '/', expires: new Date(date.setMonth(date.getMonth()+3)) })
    setVisible(false)
  }

  return(
    <Transition
      timeout={500}
      // mountOnEnter
      unmountOnExit
      in={visible}
      addEndListener={(node, done) => {
        if(visible && !state.cookieNoticeFired){
          dispatch({
            type: 'SET_COOKIE_NOTICE_FIRED',
            cookieNoticeFired: true
          })
          gsap.fromTo(node,{
            autoAlpha:0,
            y:-8
          },{
            autoAlpha:1,
            delay:1,
            y:0,
            duration:.5,
            onComplete:done
          })
        }else{
          gsap.to(node,{
            autoAlpha:0,
            duration:.5,
            y:0,
            overwrite:'all',
            onComplete:done
          })
        }
      }}
    >
      {state => (
        <CookieNoticeWrapper
            onMouseEnter={()=>{
              dispatch({
                type: 'SET_CURSOR',
                cursor:{
                  visible:false
                }
              })
            }}
        >
          <h3>Cookie Notice 🍪</h3>
          <div className="close-icon" onClick={()=>setConsent(true)}>
            <div className="line" />
            <div className="line" />
          </div>
          <p>We Are Hero ApS uses cookies to improve site functionality and provide you with a better browsing experience. Detailed information on the use of cookies on this Site, and how you can decline them, is provided in our cookie policy. By using this Site or clicking on "Accept", you consent to the use of cookies.</p>
          <CTA theme="dark"><span onClick={()=>setConsent(true)}>Accept</span></CTA>
        </CookieNoticeWrapper>
      )}

    </Transition>
  )
}

export default CookieNotice