import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/linkResolver';

const ParagraphWrapper = styled.div`
  font-size:${props => props.punchline ? props.theme.fontSizes.mobile.l : props.theme.fontSizes.mobile.m};
  line-height:1.25;
  font-style:${props => props.punchline && `italic`};
  text-transform:${props => props.punchline && `uppercase`};
  width:${props => props.punchline && `15ch`};
  @media (min-width: 562px) {
    max-width:33ch;
  }
  @media (min-width: 562px) {
    font-size:${props => props.punchline ? props.theme.fontSizes.tablet.l : props.theme.fontSizes.tablet.m };
  }
  @media (min-width: 1280px) {
    font-size:${props => props.punchline ? props.theme.fontSizes.desktop.l : props.theme.fontSizes.desktop.m};
  }
`

const Paragraph = (props) => {
  const{
    data,
    children,
    punchline
  } = props

  return(
    <ParagraphWrapper punchline={punchline}>
      {children ? children : RichText.render(data, linkResolver)}
    </ParagraphWrapper>
  )
}

export default Paragraph