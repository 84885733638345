import React, { useContext, useRef, useState, useEffect } from "react"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import TransitionLink from "gatsby-plugin-transition-link"
import { linkResolver } from "../../utils/linkResolver"
import gsap from "gsap"
import get from "lodash/get"
import Cases from "./cases"
import Description from "../Description"
import CTA from "../CTA"

const NavWrapper = styled.nav`
  background: ${props => props.theme.colors.primary};
  position: fixed;
  top: 0;
  left: 0%;
  overflow: scroll;
  z-index: 2;
  padding-top: 5em;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  overflow-x: hidden;
  @media (min-width: 562px) {
    padding-top: 5.5em;
  }
  @media (max-width: 562px) {
    .secondary-nav {
      > * {
        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    padding-top: initial;
    .secondary-nav {
      display: flex;
      > * {
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }
  }

  .description-outer-wrapper {
    padding: 0 1.5em;
    @media (max-width: 562px) {
      margin-bottom: 1.5em;
    }
    @media (min-width: 1024px) {
      padding: 0 3em;
      position: fixed;
      width: 100%;
      left: 3rem;
      top: 9em;
      padding: 0;
    }
    p {
      margin-bottom: 1em;
    }
  }

  .links {
    padding: 1.5em 1.5em 3em 1.5em;
    @media (min-width: 562px) {
      padding: 3em;
    }
    @media (min-width: 1024px) {
      position: fixed;
      bottom: 3rem;
      left: 3rem;
      padding: 0;
    }
    text-transform: uppercase;
    font-style: italic;
    li {
      &:not(:last-child) {
        font-size: ${props => props.theme.fontSizes.mobile.l};
        @media (min-width: 562px) {
          font-size: ${props => props.theme.fontSizes.tablet.l};
        }
        @media (min-width: 1024px) {
          font-size: ${props => props.theme.fontSizes.desktop.m};
        }
        line-height: 1.5;
      }
      &:last-child {
        margin-top: 1.5rem;
      }
    }
  }
`

const Nav = props => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      prismic {
        allAbouts {
          edges {
            node {
              description
              teaser
              email {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              phone {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              _meta {
                uid
                type
              }
            }
          }
        }
        allLayouts {
          edges {
            node {
              nav {
                link {
                  ... on PRISMIC_About {
                    thumbnail_file
                    _meta {
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Case {
                    _meta {
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    _meta {
                      type
                      uid
                    }
                  }
                }
                title
              }
              work {
                case {
                  ... on PRISMIC_Case {
                    title_line_1
                    title_line_2
                    _meta {
                      type
                      uid
                    }
                    thumbnail_file
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const work = get(data, "prismic.allLayouts.edges[0].node.work")
  const nav = get(data, "prismic.allLayouts.edges[0].node.nav")
  const aboutData = get(data, "prismic.allAbouts.edges[0].node")
  const aboutDescription = get(aboutData, "description")
  const aboutDescriptionTeaser = get(aboutData, "teaser")
  const email = get(aboutData, "email.url")
  const phone = get(aboutData, "phone.url")

  return (
    <Transition
      timeout={500}
      mountOnEnter
      unmountOnExit
      in={state && state.navActive}
      addEndListener={(node, done) => {
        if (state.navActive) {
          gsap.fromTo(
            node,
            {
              autoAlpha: 0,
            },
            {
              autoAlpha: 1,
              duration: 0.5,
              ease: "power2.Out",
              onComplete: done,
            }
          )
          dispatch({
            type: "SET_CURSOR",
            cursor: {
              visible: false,
            },
          })
        } else {
          gsap.fromTo(
            node,
            {
              autoAlpha: 1,
            },
            {
              autoAlpha: 0,
              duration: 0.5,
              ease: "power2.Out",
              onComplete: done,
            }
          )
        }
      }}
    >
      {state => (
        <NavWrapper className="fullWidth fullHeight">
          <div className="description-outer-wrapper">
            <TransitionLink
              to="/about"
              entry={{
                trigger: ({ node, e, exit, entry }) => {
                  window.scrollTo(0, 0)
                  dispatch({
                    type: "SET_NAV_ACTIVE",
                    navActive: false,
                  })
                },
              }}
            >
              <div style={{ fontSize: "3em", fontStyle: "italic" }}>
                READ MORE
                <br />
                ABOUT HERO
              </div>
            </TransitionLink>
          </div>

          <ul className="links">
            <li>{email && <a href={email}>{email.split("mailto:")[1]}</a>}</li>
            <li>{phone && <a href={phone}>{phone.split("tel:")[1]}</a>}</li>
            <li>Wilders Plads 9A, 1st Floor, 1403 Copenhagen</li>
            <li>
              <div className="secondary-nav">
                {nav &&
                  nav.map(item => {
                    const title = get(item, "title[0].text")
                    const meta = get(item, "link._meta")
                    if (!meta) {
                      return false
                    }
                    return (
                      <div>
                        <TransitionLink
                          to={linkResolver(meta)}
                          entry={{
                            trigger: ({ node, e, exit, entry }) => {
                              window.scrollTo(0, 0)
                              dispatch({
                                type: "SET_NAV_ACTIVE",
                                navActive: false,
                              })
                            },
                          }}
                        >
                          <CTA theme="light">{title}</CTA>
                        </TransitionLink>
                      </div>
                    )
                  })}
                <div>
                  <CTA theme="light">Linkedin</CTA>
                </div>
                <div>
                  <CTA theme="light">Instagram</CTA>
                </div>
              </div>
            </li>
          </ul>

          <Cases cases={work} />
        </NavWrapper>
      )}
    </Transition>
  )
}

export default Nav
