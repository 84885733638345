import React, {
  useEffect,
  useContext,
  useState
} from 'react'

import PropTypes from "prop-types"

import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../context/GlobalContextProvider'

import {
  createGlobalStyle,
  ThemeProvider
} from 'styled-components'

import Helmet from 'react-helmet'

import {em} from 'polished'
import get from 'lodash/get'

import reset from 'styled-reset-advanced'

import { useWindowSize } from '@react-hook/window-size/throttled'

import Header from './Header'
import Nav from './Nav'
import CookieNotice from './CookieNotice'
import Watermark from './Watermark'
import Cursor from './Cursor'
import Loader from './Loader'

const theme = {
  fontSizes:{
    mobile:{
      s:`${em(12)}`,
      m:`${em(16)}`,
      l:`${em(24)}`,
      xl:`${em(32)}`,
    },
    tablet:{
      s:`${em(16)}`,
      m:`${em(24)}`,
      l:`${em(32)}`,
      xl:`${em(48)}`,
    },
    desktop:{
      s:`${em(16)}`,
      m:`${em(32)}`,
      l:`${em(64)}`,
      xl:`${em(120)}`,
    }
  },
  colors:{
    darkest:'#000',
    lightest:'#FFF',
    primary:'#DF5036',
  },
}

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: 0px;
    -ms-overflow-style: none;
    font-family: 'Poppins', sans-serif;
    font-weight:800;
    line-height: 1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border: none;
    }
  }

  img,video {
    width:100%;
    height:auto;
  }

  *:focus {
    outline: none;
  }

  .fullHeight{
    height:${props => props.height}px;
    height:fill-available;
    height:100vh;
  }

  .fullWidth{
    width:${props => props.width}px;
    width:fill-available;
    width:100vw;
  }

  html{
    font-family: 'Poppins', sans-serif;
    background:${props => theme.colors.darkest};
    color:${props => theme.colors.lightest};
  }

  html, body {
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow-x:hidden;
  }
  .main-container{
    min-height:100vw;
  }
  .tl-wrapper{
    background:${props => theme.colors.darkest};
  }
`

const Layout = ({ children }) => {

  const [width, height] = useWindowSize()
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const prodUrl = 'https://wearehero.dk'
  const prodHost = prodUrl.replace(/https?:\/\//i, "")
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  const [isProduction, setIsProduction] = useState(false)

  useEffect(()=>{
    hostname === prodHost && setIsProduction(true)
  },[isProduction, hostname, prodHost])

  useEffect(()=>{
    dispatch({
      type: 'SET_WINDOWSIZE',
      windowSize:{
        width:width,
        height:height
      }
    })
  },[width,height,dispatch])

  // useEffect(()=>{
  //   console.log(mousePos.x)
  //   dispatch({
  //     type: 'SET_CURSOR',
  //     pos:mousePos,
  //     visible:false,
  //     text: 'dkdkdkk'
  //   })
  // },[mousePos, dispatch])

  // useEffect(()=>{
  //   const layoutData = get(data, 'prismic.allLayouts.edges[0].node')
  //   const aboutData = get(data, 'prismic.allAbouts.edges[0].node')
  //   dispatch({
  //     type: 'SET_WORK',
  //     work: get(layoutData, 'work')
  //   })
  //   dispatch({
  //     type: 'SET_STATEMENTS',
  //     statements: get(layoutData, 'work')
  //   })
  //   dispatch({
  //     type: 'SET_ABOUT',
  //     about: aboutData
  //   })
  // },[data])

  // useEffect(()=>{
  //   console.log(state)
  // },[state.work])

  return (
    <>
      {typeof window !== 'undefined' && !isProduction &&
        <Helmet
          script={[{
            type: 'text/javascript',
            src: '//static.cdn.prismic.io/prismic.min.js?new=true'
          }]}

        />
      }
      <GlobalStyle height={height} width={width} />
      <ThemeProvider theme={theme}>
        <Watermark />
        <Header />
        <main className="main-container">{children}</main>
        <Nav />
        <CookieNotice />
        <Cursor />
        {/* <Loader /> */}
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
