import React, {useRef, useEffect, useContext} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'

const NaviconWrapper = styled.div`
  width:24px;
  height:12px;
  position:relative;
  cursor:pointer;
`

const Line = styled.aside`
  width:24px;
  height:2px;
  background:${props => props.theme.colors.lightest};
  position:absolute;
  top:0;
  left:0;
`


const Navicon = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const naviconWrapperRef = useRef()
  const activeTl = useRef()


  // useEffect(()=>{
  //   const layoutData = get(data, 'prismic.allLayouts.edges[0].node')
  //   const aboutData = get(data, 'prismic.allAbouts.edges[0].node')
  //   dispatch({
  //     type: 'SET_WORK',
  //     work: get(layoutData, 'work')
  //   })
  //   dispatch({
  //     type: 'SET_STATEMENTS',
  //     statements: get(layoutData, 'work')
  //   })
  //   dispatch({
  //     type: 'SET_ABOUT',
  //     about: aboutData
  //   })
  // },[data])

  useEffect(()=>{
    const lines = gsap.utils.toArray(naviconWrapperRef.current.querySelectorAll('.line'));

    activeTl.current = gsap.timeline({
      paused:true,
    })
    .fromTo(lines[0],{
      y:0
    }, {
      y: 6,
      x:0,
      duration:.125
    },'move')
    .fromTo(lines[1],{
      y:6
    }, {
      y: 6,
      duration:.125
    },'move')
    .fromTo(lines[2],{
      y:12
    }, {
      y: 6,
      duration:.125
    },'move')
    .fromTo(lines[0],{
      rotation:0
    }, {
      rotation: 45,
      duration:.25
    },'rotate')
    .set(lines[1],{
      autoAlpha:0,
      overwrite:'auto'
    },'rotate')
    .fromTo(lines[2],{
      rotation:0
    }, {
      rotation: -45,
      duration:.25
    },'rotate')
  },[])

  useEffect(()=>{
    if(activeTl.current){
      state.navActive ? activeTl.current.play() : activeTl.current.reverse()
    }
  },[state])

  return(
    <NaviconWrapper ref={naviconWrapperRef}
      onClick={()=>{
        dispatch({
            type: 'SET_NAV_ACTIVE',
            navActive: !state.navActive
          })
      }}
    >
      <Line className="line" />
      <Line className="line" />
      <Line className="line" />
    </NaviconWrapper>
  )
}

export default Navicon