import React from 'react'
import styled from 'styled-components'
import CTA from '../CTA'
import Paragraph from '../Paragraph'

const DescriptionWrapper = styled.div`
position:relative;
z-index:2;
  /* @media (max-width: 562px) {
    padding:0 1.5em;
  } */
  @media (min-width: 1024px) {
    width:${(652/1440)*100}%;
  }
  >*{
    &:not(:last-child){
      margin-bottom:1.5rem;
    }
  }
  p{
    &:not(:last-child){
      margin-bottom:1.5rem;
    }
  }
`

const Description = (props) => {
  const {
    richText,
    ctaText
  } = props
  return (
    <DescriptionWrapper className="description-wrapper animatable">
      <Paragraph data={richText} className="paragraph animatable" />
      {ctaText &&
        <CTA theme="light">{ctaText}</CTA>
      }
    </DescriptionWrapper>
  )
}

export default Description