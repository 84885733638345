import React, {useState, useRef, useEffect, useContext} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'

const CTAWrapper = styled.div`
  display:inline-block;
  position:relative;
  padding-bottom:.5em;
  overflow:hidden;
  cursor:pointer;
  transition:opacity .25s ease-out;
  will-change:opacity;
  .text-wrapper{
    color:${props =>
      props.colorTheme === 'primary' || props.colorTheme === 'dark'  ? props.theme.colors.primary :
      props.colorTheme === 'dark' ? props.theme.colors.darkest :
      props.theme.colors.lightest
    };
    transition:color .5s ease-out;
    will-change:color;
    text-transform:uppercase;
    font-style:italic;
    position:relative;
  }
  .underline{
    background:${props =>
      props.colorTheme === 'primary' || props.colorTheme === 'dark'  ? props.theme.colors.primary :
      props.theme.colors.lightest
    };
    transition:background-color .5s ease-out;
    will-change:background-color;
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    height:2px;
  }
  &:hover{
    opacity:.66;
    .text-wrapper{
      color:${props =>
        props.colorTheme === 'primary' ? props.theme.colors.lightest :
        props.colorTheme === 'dark' ? props.theme.colors.darkest :
        props.theme.colors.lightest
      };
    }
    .underline{
      background:${props =>
        props.colorTheme === 'primary' ? props.theme.colors.lightest :
        props.colorTheme === 'dark' ? props.theme.colors.darkest :
        props.theme.colors.lightest
      };
    }
  }
`


const CTA = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const [active, setActive] = useState(false),
  ctaWrapperRef = useRef(),
  tl = useRef()
  useEffect(()=>{
    const underline = ctaWrapperRef.current.querySelectorAll('.underline')
    tl.current = gsap.timeline({
      paused: true,
    })
    .to(underline,{
      xPercent:100,
      immediateRender: false,
      ease:"power2.out",
      duration:.5
    },'intro')
    .fromTo(underline,{
      xPercent:-100
    },{
      xPercent:0,
      duration:.5,
      immediateRender: false,
      ease:"power2.out"
    },'outro')
  },[])
  useEffect(()=>{
    active === true && !tl.current.isActive() && tl.current.progress(0).play()
  },[active])
  return(
    <CTAWrapper
      ref={ctaWrapperRef}
      colorTheme={props.theme ? props.theme : 'primary'}
      onMouseLeave={()=>setActive(false)}
      onMouseEnter={()=>{
        setActive(true)
        dispatch({
          type: 'SET_CURSOR',
          cursor:{
            visible:false
          }
        })
      }}
    >
      <div className="text-wrapper">
        {props.children}
      </div>
      <div className="underline" />
    </CTAWrapper>
  )
}

export default CTA